<template>
    <!-- consol -->
    <div id="console" class="pb-1 mb-0 mb-lg-1">
        <div class="row">
            <div class="col-3 d-none d-lg-block ms-auto text-center mb-3">
                <button class="btn bg-gradient-secondary btn-block mx-auto" type="button" id="smile2button" @click="start2vdo();">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#FFFFFF">
                    <path d="M6.613 18.581m9.387-9.581c0 2.209-1.791 4-4 4s-4-1.791-4-4 1.791-4 4-4 4 1.791 4 4zm-2 0c0-1.103-.896-2-2-2s-2 .897-2 2 .896 2 2 2 2-.897 2-2zm-9 0c0 3.86 3.141 7 7 7s7-3.14 7-7-3.141-7-7-7-7 3.14-7 7zm16 0c0 4.97-4.029 9-9 9s-9-4.03-9-9 4.029-9 9-9 9 4.03 9 9zm-.404 12.501c1.007 1.142-.014 2.679-1.448 2.481-1.795-.245-3.236-1.702-7.147-1.702-3.91 0-5.352 1.458-7.146 1.702-1.436.198-2.456-1.34-1.449-2.481l2.898-3.289c.559.388 1.156.725 1.79.994l-2.025 2.298c1.295-.524 3.065-1.225 5.933-1.225s4.638.7 5.933 1.224l-2.025-2.298c.634-.27 1.231-.606 1.79-.994l2.896 3.29z"/>
                </svg>
                {{$t('checkYourSmile')}}
                </button>
            </div>
            <div class="col-12 col-lg-5 accordion-header ms-auto text-left mb-3">
                <button class="btn btn-block bg-primary mx-auto text-white" type="button" data-bs-toggle="modal" data-bs-target="#menu2vdo">
                    <i class="fa fa-youtube-square ms-1" aria-hidden="true"></i>
                    {{$t('tipsAndGuideline')}}
                </button>

                <!--div class="card bg-white">
                    <div class="card-body py-1 px-3">
                        <p class="font-weight-bolder mt-2 mb-1">View My Noted</p>
                        </div>
                    </div-->
                <!--button class="btn btn-outline bg-gradient-primary btn-block mx-auto text-white" type="button" >
                    Find a garage
                    </button-->
            </div>
            <div class="col-12 col-lg-4 ms-auto mb-3" :class="{'wrapper' : Global.authority === 'ADMIN'}">
                <router-link :to="Global.brokerDetails.accountSource !== 'Affiliate' ? '/dashboard' : '/dashboard-aff'">
                     <button class="btn bg-gradient-info btn-block mx-auto" type="button" :disabled="Global.authority === 'ADMIN'">
                        {{$t('myDashboard')}}
                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                    </button>
                </router-link>
            </div>
            <div class="col-12 ms-auto text-center mb-3">
                <div class="card bg-gradient-faded-secondary op50">
                <div class="card-body py-1 px-3">
                    <p class="mb-0 text-info text-left">
                        <span class="text-sm"><i class="fa fa-bullhorn ms-1" aria-hidden="true"></i></span>&nbsp;
                        <span v-if="isLoadingAnnouncementMsg" class="spinner-border spinner-border-sm loading text-info" role="status" aria-hidden="true"></span>
                        <span class="text-sm text-white" v-html="announcementMsg[Global.locale]"/>
                    </p>
                </div>
                </div>
            </div>
        </div>
        <!-- menu2vdo -->
        <div class="modal fade" id="menu2vdo" tabindex="-1" aria-labelledby="menu2vdoLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content p-3">
                    <div class="modal-header  border-0">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="w-auto px-3 pb-3">
                            <div class="card bg-gradient-faded-light mt-2" v-for="(guideline, index) in Global.guidelineUrl" v-bind:key="'guideline' + index">
                                <div class="card-body p-3">
                                    <h5>{{getGuidelineTitle(guideline)}}</h5>
                                    <button class="btn btn-round  text-white max-w-200 bg-gradient-info mb-1 icon-move-right mt-auto" href="#" @click="openGuideline(guideline)">
                                        {{$t(guidelineBtnLabel(guideline))}}
                                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>	
                        </div>
                    </div>
                    <div class="modal-footer border-0">
                        <button type="button" class="btn btn-primary text-white bg-gradient-primary" data-bs-dismiss="modal">{{$t('menu.close')}}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- menu2vdo -->
    </div>
    <!-- consol -->
</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";
import axios from "axios";
import {marked} from "marked";

export default {
    mixins: [mixin],
    name: "Consol",
    data() {
        return {
            isLoadingAnnouncementMsg: true,
            announcementMsg: {
                th: "",
                en: "",
                zh: ""
            }
        }
    },
    created() {
        this.getAnnouncement();
    },
    methods: {
        openGuideline: function(guideline){
            let url = guideline.link
            if(guideline.mediaType === 'article'){
                if(Global.locale == 'th') url = guideline.linkTH;
                else if(Global.locale == 'zh') url = guideline.linkZH;
                else url = guideline.linkEN;
            }
            window.open(url);
        },
        guidelineBtnLabel: function(guideline){
            if(guideline.mediaType === 'article'){
                return "read";
            }else{
                return "play";
            }
        },
        getAnnouncement: async function() {
            let vm = this;
            let announcementId;
            await axios.get(Global.apiUrl + "/util/announcement/" + Global.brokerDetails.accountSource)
            .then(async function(res){
                announcementId = res.data; 
                if(announcementId){
                    if (Global.mcsToken == "") await vm.getTokenMcs();
                    let anmtResponse = await axios.get(Global.mcsURL + "/sf/getAnnouncement/?msg="+announcementId, { headers: {"Authorization": "Bearer " + Global.mcsToken} });
                    vm.announcementMsg.th = vm.translateMarkDowm(anmtResponse.data.Text_TH__c || '');
                    vm.announcementMsg.en = vm.translateMarkDowm(anmtResponse.data.Text_EN__c || '');
                    vm.announcementMsg.zh = anmtResponse.data.Text_ZH__c ? vm.translateMarkDowm(anmtResponse.data.Text_ZH__c) : vm.announcementMsg.en;
                }
            }).catch(function(res){
                console.log(res);
            }).finally(function(){
                vm.isLoadingAnnouncementMsg = false;
            });
        },
        translateMarkDowm: function(msg){
            return (marked(msg).replaceAll("<a href","<a class=\"announcement-link\" target=_blank href")).replace("<p>","").replace("</p>",""); 
        },
        getGuidelineTitle: function(guideline){
            if(Global.locale == 'th') return guideline.titleTH;
            else if(Global.locale == 'zh') return guideline.titleZH;
            else return guideline.titleEN;
        }
    }
}
</script>

<i18n>
{
    "en":{
        "checkYourSmile": "Check your smile",
        "tipsAndGuideline": "Tips & Guideline in 5 Minutes",
        "myDashboard": "My Dashboard",
        "read": "Read",
        "play": "Play",
    },
    "th":{
        "checkYourSmile": "วันนี้คุณยิ้มแล้วหรือยัง",
        "tipsAndGuideline": "แนะนำเคล็ดลับใน 5 นาที",
        "myDashboard": "แดชบอร์ดของฉัน",
        "read": "Read",
        "play": "Play"
    },
    "zh":{
        "checkYourSmile": "Check your smile",
        "tipsAndGuideline": "Tips & Guideline in 5 Minutes",
        "myDashboard": "My Dashboard",
        "read": "Read",
        "play": "Play",
    }
}
</i18n>
