export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "checkYourSmile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your smile"])},
        "tipsAndGuideline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips & Guideline in 5 Minutes"])},
        "myDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Dashboard"])},
        "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
        "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])}
      },
      "th": {
        "checkYourSmile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันนี้คุณยิ้มแล้วหรือยัง"])},
        "tipsAndGuideline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แนะนำเคล็ดลับใน 5 นาที"])},
        "myDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แดชบอร์ดของฉัน"])},
        "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
        "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])}
      },
      "zh": {
        "checkYourSmile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your smile"])},
        "tipsAndGuideline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips & Guideline in 5 Minutes"])},
        "myDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Dashboard"])},
        "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
        "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])}
      }
    }
  })
}
